@import 'variables';
@import 'typo';
@import 'layout';
@import 'buttons';


body {  
  background-color: $bgcolor;
  color: $maincolor;
}

a {
  color: $maincolor;
}

body, a, .proj-img.txt {
  // color: $maincolor !important; 
}

ul,
li {
  list-style: none;
  margin:0;
  padding:0;
}

nav li {
  display: inline-block;  
}

header {

  a {
    text-decoration: none;
  }

  
  // a:hover,a.active, .logo a {
  //   color: white;
  // }
  
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}



 
.active, a:hover{
  color: $highlightcolor;
}

