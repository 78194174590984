
.info-btn, .pics-btn {
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
}

.pics-btn {
  display: none;
}

.tool {
  cursor:pointer;
  max-width:150px;  
  padding-left: 20px;
  position: relative;
  &:before {
    content:'';
    position: absolute;
    display: inline-block;
    width:10px;
    height: 10px;
    border:2px solid $maincolor;
    left:0;
    top:3px;
  }
  &.on:before {
    background:$maincolor;
  }
}

.pager-dot {
  width: 10px;
  height: 10px;
  border:1px solid $maincolor;
  border-radius:50%;
  display: inline-block;
}

.load-more {
  margin:15px auto;
  padding:7px;
  width:calc(100% - 14px);
  text-align: center;
}