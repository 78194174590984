html,body {
  height: 100%;
}

.logo {
  position: fixed;
  top:10px;
  // left:20px;
  left:0;right:0;
  margin:0 auto;
  text-align: center;
  
  z-index: 22222222;
}

.tagfilter {
  top:75px;
  left:50px;
  position: fixed;
  text-transform: lowercase;
}



.home {
  #container, main {
    height:100%;
  }
  .projects {
    display: none;
  }
}



.projects {
  padding: 150px 20px;
  .project {
    display: inline-block;
    max-width: 200px;
    padding:35px;
  }
} 


.gallery {
  position: absolute;    
  margin:0 auto;
  top:0;
  left:0;
  right:0;
  width:calc(100% - 200px);
  max-width: 950px;
  margin:0 auto;
  height:100%;
  // background:yellow;
  text-align: center;
  overflow: hidden;
  z-index: 0;
}

.t {
    position: absolute;
    width: 50%;
    background:rgba(255,0,0,0);
    height: 100%;
    z-index: 1;
    cursor: pointer;
  }

  .cprev {
  // display: none;
  }
  .cnext {
    left: 50%;
  }

.proj-img {
  // cursor: pointer;
  text-align: center;
  position: absolute;
  top:0;
  left:0;
  right:0;
  margin:0 auto;
  // max-width:940px;
  max-width:calc(100% - 200px);
  height: 100%;
  max-height: 100%;
  // max-height:100%;    
  display: none;
  width: 100%;

  &.active {
    display: block;
  }

  .pholder {
    // background: pink;
    margin-top: 40px;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  img, video {
    // width:100%;
    max-width: 100%;
    max-height:100%;
    // height:auto;
    margin:0 auto;
  }

  &.txt {
    text-align: justify;
    padding: 40px 200px 60px 200px;
    color:$maincolor;
    max-width:720px;
    user-select: none;
    min-height: calc(100% - 100px);
    max-height: none;
    height: auto;
    h4 {
      margin-bottom: 0;
    }
  }
}

.proj-title {
  color: $maincolor;
  text-align: left;
  position: fixed;
  bottom:20px;
  a {
    text-decoration: none;
  } 
}
.m-proj-info {
  color: $maincolor;
  // text-align: right;
  position: fixed;
  bottom:20px;
  right:17.5%;//250px;
  z-index: 789;
  
    &:hover {
      color:$highlightcolor;
    }
  
}

.gallery-pager {
  z-index: 666888888;
  position: fixed;
  left:0;
  right: 0;
  bottom:50px;
  text-align: center;
  width: 100px;
  margin: 0 auto;
}

.showlayers .visible {
  display: block;
}

.showinfo {
  .gallery, .gallery-pager, .tools {
    opacity: 0.05;
  }
  .project-detail .description {
    display: block;
  }
}


.proj-info {
  z-index: 1;
  position: fixed;
  margin-top:100px;
  // background: pink;
  display: inline-block;
  max-width:300px;
}

.tools {
  position: fixed;
  right:50px;
  bottom:20px;  
}

.prevnext {
  position: fixed;
  left:50px;
  bottom:20px;  

  .next {
    margin-left:10px;
  }
}

 body.contact-page,
 body.news-page {
  height:auto;
}
.contact {
  padding-left:20px;
  margin-top: 30px;
  margin-bottom: 30px;
  .left,.right {
    display: inline-block;
    vertical-align: top;
    min-width:200px;
  }
  .left {
    // position: fixed;
    // top:200px;
    // left:50px;

    .credits {
      margin-top: 47px;
      margin-bottom: 30px;
    }
  }
  .right {
    // margin:50px 0 0 375px;
    // max-width: calc(100% - 340px);
  }

  nav {
    margin-top: 30px;
  }
}



.person {
  margin-bottom: 20px;
  .pname {
    font-weight: bold;
  }
}

.publications {
  // padding:10px;
  // max-width:900px;
  margin-top: 30px;
  padding-bottom:40px;
  .publication {
    display: inline-block;
    max-width:calc(100%/3 - 44px);
    // background:pink;
    .img, .info {
      display: inline-block;
      vertical-align: bottom;
      max-width:calc(50% - 9px);
    }
    .img {
      margin-right: 10px;
    }
    .dl {
      display: block;
      margin-top: 10px;
    }
    .info p {
      padding:0;
      margin:0;
    }
    margin: 20px;
  }
}

.news {
  margin-top:30px;
  padding-left:20px;
  padding-right:20px;
  .left {
    max-width: 500px;
  }
}




/* -----------------
  MATRIX STYLE
------------------ */
.m-meta, .m-project {
  position: absolute;
  z-index: 2;
  background:$bgcolor;
  //background:rgba(255,0,0,0.2);
  padding:40px 20px;
  width:160px;
  a {
    text-decoration: none;
  }
}
// .m-type {
//   top:20px;//100px;
//   width: 100%;
//   left: 0;
//   right: 0;
//   text-align: center;
// }

.m-type, .m-new {
  margin-bottom: 30px;
}

.m-project {
  top:0;
  left:0;
  text-transform: lowercase;
}
.gallery {
  position: fixed;
}

.m-meta {
  top:0;
  right:0;
}

.m-filters {
  display: none;
  position: fixed;
  bottom:0;
  // bottom: auto;
  // top: 50%;
  bottom: 50px;
  // background:$bgcolor;
  padding:10px;
  width:100%;
  height:20px;
  margin:0 auto;
  text-align: center;
  z-index: 909090;
}

.m-filter {
  position: fixed;
  bottom:50px;
  // bottom: auto;
  // top: 50%;
  cursor: pointer;
  &.on {
    font-weight: bold;
  }
}
.f-project {
  left:20px;
}
.f-theme {
  right:20px;
}

.kint {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  z-index: 999;
}



/* HAMMER TIME */

body.home {
  overflow: hidden;
  // background:blue;
}

.imageContainer {
  // background:pink;
  width: 100%;//96%;
  height: 100%;//calc(100% - 20px);//96%;
  // max-width: 800px;
  // max-height: 600px;
  position: absolute;
  // overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  // background: #2b2b2c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}


.imageContainer > img {
  display: block;
  max-width:none;//100%;
  max-height:none;//100%;
  cursor: move;
  touch-action: none;  
}

#map {
  height: 100%;
  border-left:40px solid white;
  border-right:40px solid white;
  background:white;
  &:after {
    position: fixed;
    bottom:0px;
    content:'';
    height:40px;
    background:white;
    width:100%;
  }
  &:before {
    position: fixed;
    top:0px;
    content:'';
    height:40px;
    background:white;
    width:100%;
    z-index: 123456;
  }
  
}


.home button {
  //display: none;
}

.labels{
  position: relative;
  top:10px;
  margin: auto;
  pointer-events:none;  
}

.b a{
  // border:1px solid blue;
  background:transparent;
  pointer-events:auto;
  position: absolute;
  color:transparent;
  text-decoration: none;
  text-align: center;
  background:transparent;
  padding:60px 10px;

  span {
    padding:5px;
  }
  &:hover {
    
    span {
      //background:rgba(255,255,255,0.3);
      //box-shadow: 3px 3px 5px rgba(255, 255, 255, 0.73);
      position: relative;
      &:before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0; 
        bottom: 0;
        // width:100%;         
        box-shadow: inset 0 0 0 100px rgba(255,255,255,0.8);
        filter: blur(10px);
        z-index: -1;
      }
        
      // border:1px solid $highlightcolor;
      border-radius:15px; 
    }
    color:$maincolor;
    font-weight: bold;
    font-size: 2rem;

  }
}
.b1 a{
  width: 80px;
  height: 110px;
  top: 915px;
  left: 545px; 
}
.b2 a{
  top: 759px;
  left: 1510px;
  width: 80px;
  height: 120px;
}
.b3 a{
  top: 360px;
  left: 790px;
  width: 390px;
  height: 0;
}
.b4 a{
  top: 610px;
  left: 790px;
  width: 290px;
  height: 0px;
}
.b5 a{
  top: 730px;
  left: 1050px;
  width: 120px;
  height: 60px;
}
.b6 a{
  top: 660px;
  left: 527px;
  width: 160px;
  height: 70px;
}
.b7 a{
  top: 345px;
  left: 1340px;
  width: 820px;
  height: 0;
}
.b8 a{
  top: 950px;
  left: 1290px;
  width: 150px;
  height: 210px;
}
.b9 a{
  top: 790px;
  left: 350px;
  width: 142px;
  height: 110px;
}
.b10 a{
  width:80px;
  height:130px;
  top:1030px;
  left:765px;  
}
.b11 a{
  top: 620px;
  left: 1275px;
  width: 150px;
  height: 130px;
}
.b12 a{
  top: 710px;
  left: 1660px;
  width: 184px;
  height: 40px;
}
.b13 a{
  top: 1000px;
  left: 1921px;
  width: 217px;
  height: 160px;
}
/*
.fb {
  a {
    display: block;
    background:transparent;
    width:120px;
    height:120px;
  }
  position:absolute;
  bottom:190px;
  left:400px;
  border:0px;
  background:transparent;
  &:hover {
    background:$highlightcolor;
    mix-blend-mode: screen;
    cursor: pointer;
  }
}
.pubs {
  a {
    display: block;
    background:transparent;
    width:150px;
    height:100px;
  }
  position:absolute;
  bottom:190px;
  left:1100px;
  border:0px;
  background:transparent;
  &:hover {
    background:$highlightcolor;
    mix-blend-mode: screen;
    cursor: pointer;
  }
}
*/

/* END HAMMER TIME */

.m-project, .m-meta {
  a {
    opacity: 0.65;

    &:hover {
      opacity: 1;
    }
  }
}
.highlighted {
  margin-right:-5px;
  font-weight:bold;
  opacity: 1 !important;
}
// .active.highlighted {
//   &:before {
//     content:'';
//   }
// }

.m-project .highlighted {
  margin-left:-5px;
}



/* maps marker */
.popup-tip-anchor {
  height: 0;
  position: absolute;
  width: 200px;

}
.popup-bubble-content {
  
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  // background-color: white;
  padding: 5px;
  // border-radius: 5px;
  font-family: sans-serif;
  font-size:1rem;
  overflow-y: auto;
  background:transparent;
  // box-shadow: 0px 0px 0px 100px rgba(0,0,0,0.5);

  // start

  &:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0; 
    bottom: 0;
    width:100%;         
    box-shadow: inset 0 0 0 1000px rgba(255,255,255,0.6);
    filter: blur(5px);
    z-index: -1;
    border-radius:15px;
    // background:white;
  }    

  // end
}

.label {
  //display: none;
  opacity: 0.25;
  &:hover {
    opacity: 1;
  }
  a {
    text-decoration: none;
    font-size: 0.8rem;
  }
}

.activeHouse {
  opacity:1;
}

.tooltip {
  z-index: 1;
  padding:2px 10px;
  color:$highlightcolor;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0; 
    bottom: 0;
    width:100%;         
    box-shadow: inset 0 0 0 1000px rgba(255,255,255,0.8);
    filter: blur(6px);
    z-index: -1;
    border-radius:15px;
    // background:white;
  }    
}

.intro {
  position: fixed;
  top:48%;
  left:0;
  right:0;
  padding:10px;
  color:$highlightcolor;
  // background:white;  
  // max-width:460px;
  
  width: 100px;
  margin:0 auto;
  text-align: center;
  &:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0; 
    bottom: 0;
    width:100%;         
    box-shadow: inset 0 0 0 1000px rgba(255,255,255,0.8);
    filter: blur(6px);
    z-index: -1;
    border-radius:15px;
    // background:white;
  }  
}
/* end maps marker */

.gp nav, .work nav {
  li{margin: 5px;padding-top: 5px;}  
  background:$bgcolor;
}

.gp nav, .home footer, .work nav {
  position: fixed;
  bottom:0;
  left:0;
  width:100%;
  height: 40px;
  text-align: center;
  
  a {
    text-decoration: none;
  }
}

.work nav {
  z-index: 123456;
}

.home footer  {
  background:white;
  .permlabel {
    display: inline-block;
    padding-top: 10px;
    a {      
      margin:0 5px;
    }
  }

  .sb {
    display: inline-block;
    padding-top: 10px;
    img {
      max-width:20px;  
    }
    

  }
}


// responsive youtube test
.player-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
#player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.playbtn {
  position: fixed;
  width: 50px;
  height: 50px;
  // background-color:red;
  top: calc(50% - 25px);
  left:calc(50% - 25px);
  display: none;
  z-index: 1234567890;
}


/* -----------------
  MEDIA QUERIES
------------------ */

@media (max-width: 1200px) {
  
}

@media (max-width: 850px) {

  .m-filters {
    display: block;
  }
  .m-meta,.m-project {
    display: none;
    //z-index: 90909090;
    
  }

  .m-meta-open .m-meta {
    display: block;
    // background:white;
    width: calc(50% - 40px);
    //background: red;
    height: auto;//calc(100% + 40px);
    min-height:calc(100% - 160px);
    padding-bottom: 80px;
  }

  .m-project-open .m-project {
    display: block;
    // background:white;
    width: calc(50% - 40px);
    height: auto;//calc(100% - 80px);
    min-height:calc(100% - 160px);
    padding-bottom: 80px;
  }

  .m-meta-open, .m-project-open {
    .m-filters {
      // display: none;
    }
  }

  .gallery, .proj-img {
    width: 100%;
    max-width: none;
  }
  
  .gallery-pager {
    // bottom:10px;

    &.closed {
      //display: none;
    }
  }

  .proj-img.txt {
    text-align: left;
    padding: 40px 10px 60px 10px;
    max-width:760px;
  }


  .publications .publication{
    max-width:calc(100%/2 - 44px);
  }
  
  
}

@media (max-width: 600px) {

  .logo {
    top:10px;
    left:20px;
  }
  .home {
    #panzoom-parent {
      // display: none;
    }
    .projects{
      // display: block;
    }
  }

  .projects {
    padding: 20px;
    .project {
      max-width: 100%;
      padding:20px 0;
    }
  } 
  .tagfilter {
    top:45px;
    left:20px;
  }

  .project-detail {
    padding:40px 20px;

    .gallery {
      position: relative;
      .proj-img {
        position: relative;
        top:20px !important;
      }
    }
    .description {
      position: relative;
      width:100%;
      left:0;
      top:0;
    }
    .tags {
      margin-top: 20px;
    }

  }
  .proj-info {
    margin-top: 40px;
    max-width:100%;
    position: relative;
    padding-bottom:40px;
  }
  .prevnext {
    left:20px;
  }
  .tools {
    right:20px;
  }
  
  .gallery-pager {
    // bottom:10px;
    // bottom: auto;
    // top: 50%;
  }

  /*.contact {
    .left {
      position: relative;
      top:60px;
      left:0;
      margin: 0 0 0 20px;
      // background:pink;
    }
      .right {
        // background:blue;
        margin:100px 0 0 20px;
        max-width: 100%;
        display: block;
      }
  }*/

  .publications .publication{
    max-width:calc(100% - 44px);
  }


}

@media (max-width: 400px) {
  .home .logo {
    top:5px;
  }
  #map {
    border-left:0px;
    border-right:0px;
    &:before, &:after {
      height:30px;
    }
  }
  .home footer .permlabel {
    padding-top: 10px;
  }
  .gp nav, .home footer {
    height:40px;
  }
  .gp nav li {
    padding-top: 0;
  }

  .contact .left {
    .credits {
      margin-top: 17px;
      margin-bottom: 10px;
    }
  }



}

.sbox {
  position: fixed;
  z-index: 1;
}