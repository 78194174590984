
html {
  -webkit-font-smoothing: antialiased;
}

body {  
  font-family: Helvetica Neue, sans-serif; // Neuzeit Grotesk,
  font-size: 0.85rem;
  line-height: 1.25rem;
  font-weight: normal;
  margin:0;
}

.logo {
  font-weight: bold;
  font-size: 1rem;//1.25rem;//3.0rem;
  //line-height:3.5rem;  
  text-transform: lowercase;
}

.b a {
  font-size: 1.75rem;
  line-height:2rem;
  text-transform: lowercase;
}

.m-project, .m-meta {
  //text-transform: lowercase;
}
.m-meta {
  text-align: right;
}

.project-detail, .contact {
  h1 {
    font-size: 1.25rem;
    margin: 0;
  }
  .subtitle {
    font-style: italic;
  }
  .description {
    font-size: 1.175rem;
    line-height:1.5rem;
  }
}

.tag, .m-filter {
  text-transform: lowercase;
}

.m-filter.active {
  font-weight: bold;
}

.txt {
  font-size: 1rem;
}

.projects h3 {
    // font-weight: NORMAL;
    font-size: 1rem;
    margin: 0;
  
}

.contact .credits {
  font-size: 0.65rem;
  a {text-decoration: none;}
}